import React from "react";
import {ppmToHexColor} from "../utils/ppm-to-hex-color";

const min = 400;
const max = 1200;

const PPPMErrorLegend: React.FC = () => {

    // Generate gradient stops by reusing the original ppmToHexColor function
    const gradientStops = Array.from({ length: 11 }, (_, i) => {
        const ppm = min + (i * (max - min)) / 10;
        return ppmToHexColor(ppm);
    }).join(", ");

    return (
        <div className="d-flex flex-column align-items-center my-4 w-25 m-auto float-end mr-30">
            {/* Gradient bar */}
            <div
                className="w-100"
                style={{
                    height: "8px",
                    background: `linear-gradient(to right, ${gradientStops})`,
                }}
            />
            {/* Labels */}
            <div className="d-flex justify-content-between w-100 mt-2">
                <small style={{
                    fontSize: "0.75em",
                }}>{min} PPM</small>
                <small style={{
                    fontSize: "0.75em",
                }}>{max} PPM</small>
            </div>
        </div>
    );
};

export default PPPMErrorLegend;