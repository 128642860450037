import React, {FormEvent, useEffect, useState} from "react";
import { analytics, createOrder } from "../../firebase";
import { generatePath, useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { IDENTIFICATION_ORDER } from "../../Routes";
import accept from "attr-accept";
import { MachineType } from "../../typings";
import { MachineTypeSelector } from "../../components/upload/MachineTypeSelector";
import { UploadErrorMessages } from "../../components/upload/UploadErrorMessages";
import { getAcceptedFileType } from "../../utils/get-accepted-file-type";
import { useActiveLicence } from "../../hooks/useActiveLicence";
import {UploadDropZone} from "../../components/upload/UploadDropZone";
import usePreventUnload from "../../hooks/usePreventUnload";
import {RoleProtectedWrapper} from "../../components/auth/RoleProtectedWrapper";

export const IdentificationUpload: React.FC = () => {
  const [machineType, setMachineType] = useState<MachineType>();
  const [database, setDatabase] = useState("All");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [customerReference, setCustomerReference] = useState<string>();
  const [submitError, setSubmitError] = useState("");

  const navigate = useNavigate();
  const [hasActiveLicence] = useActiveLicence();
  usePreventUnload(isUploading);


  const acceptedFileType = getAcceptedFileType(machineType);

  const handleClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setSubmitError("");
    setMachineType(undefined);
    setSelectedFiles([]);
  };

  const handleSubmit = async (e: FormEvent) => {
    if (!machineType) return;
    setIsUploading(true);
    e.preventDefault();

    try {
      const result = await createOrder(
        machineType,
        database,
        selectedFiles,
        customerReference
      );
      console.log("order started ", result);
      logEvent(analytics, "user_submitted_order", {
        filesCount: selectedFiles.length,
        machineType,
        database,
        selectedFiles,
      });
      setTimeout(() => {
        navigate(generatePath(IDENTIFICATION_ORDER, { orderId: result.data }));
      }, 5000);
    } catch (e) {
      setIsUploading(false);
      setSubmitError(
        "We have encountered unexpected problems. Please contact us at contact.mabriteccentral@mabritec.com."
      );
      logEvent(analytics, "user_failed_to_submit", {
        error: e,
      });
      console.error(e);
    }
  };

  const disableSubmit =
      !selectedFiles
          .map((file) => accept(file, acceptedFileType))
          .every(Boolean) ||
      selectedFiles.length === 0 ||
      !machineType ||
      !hasActiveLicence ||
      selectedFiles.map((file) => file.size > 30 * 1024 * 1024).every(Boolean) ||
      isUploading ||
      selectedFiles.length > 500;

  return (
      <div className="identification-upload container">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-8 mb-60">
            <div className="fw-bolder mb-20">Identification</div>
          <div>
            Identify your unknown spectra or confirm your identification result.
          </div>
        </div>
        <div className="col-12 offset-lg-2 col-lg-8">
          <form
            encType="multipart/form-data"
            id="uploadForm"
            onSubmit={handleSubmit}
          >
            <MachineTypeSelector
              machineType={machineType}
              setMachineType={setMachineType}
              availableMachineTypes={[
                "bruker",
                "shimadzuAscii",
                "clover",
                "vitekMSAscii",
                "vitekMSMzml",
              ]}
            />

            <div className="row">
              <RoleProtectedWrapper role="classifier">
                <div className="col-xl-7 col-12 mb-30">
                  <label htmlFor="database" className="fw-bolder mb-20">
                    02. Select a database:
                  </label>
                  <select
                      className="form-select text-truncate"
                      id="database"
                      name="database"
                      aria-label="Select database"
                      value={database}
                      onChange={(e) => setDatabase(e.target.value)}
                  >
                    <option value="All">All Bacteria</option>
                    <option value="Acinetobacter-baumannii-complex">
                      Acinetobacter baumannii complex
                    </option>
                    <option value="Bacillus-cereus-group">
                      Bacillus cereus group
                    </option>
                    <option value="Bordetella-pertussis-parapertussis-bronchiseptica">
                      Bordetella pertussis / parapertussis / bronchiseptica
                    </option>
                    <option value="Burkholderia-cepacia-complex">
                      Burkholderia cepacia complex
                    </option>
                    <option value="Escherichia-Shigella">
                      Escherichia / Shigella
                    </option>
                    <option value="Enterobacter-cloacae-complex">
                      Enterobacter cloacae complex
                    </option>
                    <option value="Klebsiella-Raoultella">
                      Klebsiella / Raoultella
                    </option>
                    <option value="Listeria">
                      Listeria "sensu stricto" clade
                    </option>
                    <option value="Salmonella">Salmonella</option>
                    <option value="Staphylococcus-aureus-complex">
                      Staphylococcus aureus complex
                    </option>
                    <option value="Streptococcus-mitis-group">
                      Streptococcus mitis group
                    </option>
                  </select>
                </div>
              </RoleProtectedWrapper>

              <div className="col-xl-5 col-12 mb-30">
                <div className="form-group">
                  <label htmlFor="reference" className="fw-bolder mb-20">
                    02. Add customer reference (optional):
                  </label>

                  <input
                      className="form-control"
                      type="text"
                      id="customerReference"
                      value={customerReference}
                      maxLength={30}
                      title="maximum 30 characters"
                      onChange={(event) =>
                          setCustomerReference(event.target.value)
                      }
                  />
                </div>
              </div>
            </div>

            <div className="mb-20">
              <label htmlFor="files" className="fw-bolder mb-20">
                03. Upload files:
              </label>

              <UploadDropZone
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  setMachineType={setMachineType}/>
            </div>

            <div className="d-flex gap-30 mb-20">
              <button
                  className="btn btn-outline-primary"
                  onClick={(e) => handleClear(e)}
              >
                Clear
              </button>
              <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={disableSubmit}
              >
                {isUploading ? (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    "Send"
                )}
              </button>
            </div>
            <UploadErrorMessages
                selectedFiles={selectedFiles}
                hasActiveLicence={hasActiveLicence}
                machineType={machineType}
                acceptedFileType={acceptedFileType}
                isUploading={isUploading}
              submitError={submitError}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
