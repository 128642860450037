import React, {useEffect} from "react";
import {useParams} from "react-router-dom";


const Glossary: React.FC = () => {

    const {section} = useParams();

    useEffect(() => {
        if (section) {
            const element = document.getElementById(section);
            if (element) {
                element.scrollIntoView({behavior: "smooth"});
            }
        }
    }, [section]);

    return (
        <div className="glossary container">
            <div className="row">
                <div
                    className="col-10 offset-2 mb-20"
                    id="Bacillus-cereus-group">
                    <h3>Bacillus cereus group Classifier</h3>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Bacillus albus</li>
                        <li>Bacillus anthracis</li>
                        <li>Bacillus cereus</li>
                        <li>Bacillus luti</li>
                        <li>Bacillus mobilis</li>
                        <li>Bacillus nitratireducens</li>
                        <li>Bacillus pacificus</li>
                        <li>Bacillus paramycoides</li>
                        <li>Bacillus paranthracis</li>
                        <li>Bacillus thuringiensis</li>
                        <li>Bacillus toyonensis</li>
                        <li>Bacillus tropicus</li>
                        <li>Bacillus wiedmannii</li>
                    </ul>
                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Listeria"
                >
                    <h3>Listeria 'sensu stricto' clade Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Listeria cossartiae subsp. cayugensis</li>
                        <li>Listeria cossartiae subsp. cossartiae</li>
                        <li>Listeria farberi</li>
                        <li>Listeria immobilis</li>
                        <li>Listeria innocua</li>
                        <li>Listeria ivanovii subsp. ivanovii</li>
                        <li>Listeria marthii</li>
                        <li>Listeria ivanovii subsp. londoniensis</li>
                        <li>Listeria monocytogenes Lineage I</li>
                        <li>Listeria monocytogenes Lineage II</li>
                        <li>Listeria seeligeri</li>
                        <li>Listeria monocytogenes Lineage III IV</li>
                        <li>Listeria monocytogenes Lineage III</li>
                        <li>Listeria welshimeri</li>
                    </ul>
                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Enterobacter-cloacae-complex"
                >
                    <h3>Enterobacter cloacae complex Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Enterobacter quasihormaechei</li>
                        <li>Enterobacter MabrTax13</li>
                        <li>Enterobacter asburiae</li>
                        <li>Enterobacter MabrTax14</li>
                        <li>Enterobacter cancerogenus</li>
                        <li>Enterobacter chengduensis</li>
                        <li>Enterobacter chuandaensis</li>
                        <li>Enterobacter cloacae subsp. cloacae</li>
                        <li>Enterobacter cloacae subsp. dissolvens</li>
                        <li>Enterobacter cloacae</li>
                        <li>Enterobacter dykesii</li>
                        <li>Enterobacter kobei</li>
                        <li>Enterobacter mori</li>
                        <li>Enterobacter oligotrophicus</li>
                        <li>Enterobacter quasimori</li>
                        <li>Enterobacter roggenkampii</li>
                        <li>Enterobacter sichuanensis</li>
                        <li>Enterobacter soli</li>
                        <li>Enterobacter MabrTax10</li>
                        <li>Enterobacter MabrTax11</li>
                        <li>Enterobacter MabrTax1</li>
                        <li>Enterobacter MabrTax2</li>
                        <li>Enterobacter MabrTax3</li>
                        <li>Enterobacter MabrTax4</li>
                        <li>Enterobacter pasteurii</li>
                        <li>Enterobacter bugandensis</li>
                        <li>Enterobacter hormaechei subsp. hoffmannii</li>
                        <li>Enterobacter hormaechei subsp. oharae</li>
                        <li>Enterobacter hormaechei subsp. hormaechei</li>
                        <li>Enterobacter hormaechei subsp. steigerwaltii</li>
                        <li>Enterobacter hormaechei</li>
                        <li>Enterobacter hormaechei subsp. xiangfangensis</li>
                        <li>Enterobacter huaxiensis</li>
                        <li>Enterobacter ludwigii</li>
                        <li>Enterobacter quasiroggenkampii</li>
                        <li>Enterobacter timonensis</li>
                        <li>Enterobacter vonholyi</li>
                        <li>Enterobacter wuhouensis</li>
                    </ul>
                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Acinetobacter-baumannii-complex"
                >
                    <h3>Acinetobacter baumannii complex Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Acinetobacter MabrTax14</li>
                        <li>Acinetobacter baumannii</li>
                        <li>Acinetobacter calcoaceticus</li>
                        <li>Acinetobacter lactucae</li>
                        <li>Acinetobacter geminorum</li>
                        <li>Acinetobacter nosocomialis</li>
                        <li>Acinetobacter oleivorans</li>
                        <li>Acinetobacter pittii</li>
                        <li>Acinetobacter seifertii</li>
                    </ul>
                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Streptococcus-mitis-group"
                >
                    <h3>Streptococcus mitis group Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Streptococcus halitosis</li>
                        <li>Streptococcus infantis</li>
                        <li>Streptococcus mitis</li>
                        <li>Streptococcus oralis-subsp.-dentisani</li>
                        <li>Streptococcus oralis</li>
                        <li>Streptococcus pneumoniae</li>
                        <li>Streptococcus pseudopneumoniae</li>
                        <li>Streptococcus shenyangsis</li>
                        <li>Streptococcus timonensis</li>
                    </ul>
                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Burkholderia-cepacia-complex"
                >
                    <h3>Burkholderia cepacia complex Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Burkholderia aenigmatica</li>
                        <li>Burkholderia ambifaria</li>
                        <li>Burkholderia anthina</li>
                        <li>Burkholderia arboris</li>
                        <li>Burkholderia catarinensis</li>
                        <li>Burkholderia cenocepacia</li>
                        <li>Burkholderia cepacia</li>
                        <li>Burkholderia contaminans</li>
                        <li>Burkholderia diffusa</li>
                        <li>Burkholderia dolosa</li>
                        <li>Burkholderia lata</li>
                        <li>Burkholderia latens</li>
                        <li>Burkholderia metallica</li>
                        <li>Burkholderia multivorans</li>
                        <li>Burkholderia pseudomultivorans</li>
                        <li>Burkholderia puraquae</li>
                        <li>Burkholderia pyrrocinia</li>
                        <li>Burkholderia reimsis</li>
                        <li>Burkholderia stabilis</li>
                        <li>Burkholderia stagnalis</li>
                        <li>Burkholderia territorii</li>
                        <li>Burkholderia ubonensis</li>
                        <li>Burkholderia vietnamiensis</li>
                    </ul>
                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Staphylococcus-aureus-complex"
                >
                    <h3>Staphylococcus aureus complex Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Staphylococcus argenteus</li>
                        <li>Staphylococcus aureus</li>
                        <li>Staphylococcus schweitzeri</li>
                    </ul>
                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Bacillus-subtilis-group"
                >
                    <h3>Bacillus subtilis group Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Bacillus amyloliquefaciens</li>
                        <li>Bacillus atrophaeus</li>
                        <li>Bacillus cabrialesii</li>
                        <li>Bacillus halotolerans</li>
                        <li>Bacillus inaquosorum</li>
                        <li>Bacillus MabrTax109</li>
                        <li>Bacillus MabrTax155</li>
                        <li>Bacillus MabrTax41</li>
                        <li>Bacillus mojavensis</li>
                        <li>Bacillus nakamurai</li>
                        <li>Bacillus rugosus</li>
                        <li>Bacillus siamensis</li>
                        <li>Bacillus spizizenii subsp. spizizenii</li>
                        <li>Bacillus subtilis subsp. subtilis</li>
                        <li>Bacillus tequilensis</li>
                        <li>Bacillus vallismortis</li>
                        <li>Bacillus velezensis</li>
                    </ul>
                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Klebsiella-Raoultella"
                >
                    <h3>Klebsiella / Raoultella Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Klebsiella aerogenes</li>
                        <li>Klebsiella africana</li>
                        <li>Klebsiella grimontii</li>
                        <li>Klebsiella huaxiensis</li>
                        <li>Klebsiella indica</li>
                        <li>Klebsiella michiganensis</li>
                        <li>Klebsiella oxytoca</li>
                        <li>Klebsiella pasteurii</li>
                        <li>Klebsiella pneumoniae</li>
                        <li>Klebsiella pneumoniae subsp. ozaenae</li>
                        <li>Klebsiella pneumoniae subsp. pneumoniae</li>
                        <li>Klebsiella pneumoniae subsp. rhinoscleromatis</li>
                        <li>Klebsiella quasipneumoniae</li>
                        <li>Klebsiella quasipneumoniae subsp. quasipneumoniae</li>
                        <li>Klebsiella quasipneumoniae subsp. similipneumoniae</li>
                        <li>Klebsiella quasivariicola</li>
                        <li>Klebsiella spallanzanii</li>
                        <li>Klebsiella variicola</li>
                        <li>Klebsiella variicola subsp. variicola</li>
                        <li>Raoultella electrica</li>
                        <li>Raoultella ornithinolytica</li>
                        <li>Raoultella planticola</li>
                        <li>Raoultella terrigena</li>
                    </ul>

                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Escherichia-Shigella"
                >
                    <h3>Escherichia / Shigella Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Escherichia albertii</li>
                        <li>Escherichia coli</li>
                        <li>Escherichia fergusonii</li>
                        <li>Escherichia marmotae</li>
                        <li>Escherichia whittamii</li>
                        <li>Shigella boydii</li>
                        <li>Shigella dysenteriae</li>
                        <li>Shigella flexneri</li>
                        <li>Shigella sonnei</li>
                    </ul>
                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Salmonella"
                >
                    <h3>Salmonella Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Salmonella bongori</li>
                        <li>Salmonella enterica-subsp.-arizonae</li>
                        <li>Salmonella enterica-subsp.-diarizonae</li>
                        <li>Salmonella enterica-subsp.-enterica</li>
                        <li>Salmonella enterica-subsp.-houtenae</li>
                        <li>Salmonella enterica-subsp.-indica</li>
                        <li>Salmonella enterica-subsp.-salamae</li>
                    </ul>

                </div>
                <div
                    className="col-10 offset-2 mb-20"
                    id="Bordetella-pertussis-parapertussis-bronchiseptica"
                >
                    <h3>Bordetella pertussis / parapertussis / bronchiseptica Classifier</h3>
                    <p></p>
                    <h4>Included species:</h4>
                    <ul>
                        <li>Bordetella bronchiseptica</li>
                        <li>Bordetella parapertussis</li>
                        <li>Bordetella pertussis</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Glossary;