import React, {useEffect, useState} from "react";
import { motion } from "motion/react";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";

const getBacdiveId = httpsCallable<{ species: string }, { bacdiveId: string }>(functions, "getBacdiveId");

const BacdiveLinkListElement: React.FC<{
    species: string
    key: number,
}> = ({ species, ...props }) => {
    const [linkUrl, setLinkURL] = useState<string | undefined>()

    useEffect(() => {
        getBacdiveId({species})
            .then((data) => {
                const {bacdiveId} = data.data;
                const url = `https://bacdive.dsmz.de/strain/${bacdiveId}`;
                setLinkURL(url);
            })
            .catch((error) => {
                console.error('Error fetching Bacdive id', error);
            })
    }, [species]);

    if (!linkUrl) return null;

    return <motion.li
        initial={{
            opacity: 0,
            y: 25
        }}
        animate={{
            opacity: 1,
            y: 0

        }}
        transition={{ delay: props.key * 0.2}}
        {...props}><a href={linkUrl} target="_blank" rel="noreferrer">Bacdive - {species}</a></motion.li>
}

export default BacdiveLinkListElement;