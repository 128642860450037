import React from "react";
import {Spectra} from "../../typings";
import NcbiLinkListElement from "./NcbiLinkListElement";
import BacdiveLinkListElement from "./BacdiveLinkListElement";
import GlossaryLink from "./GlossaryLink";

type ExternalLinkProps= {
    spectra: Spectra
}

const ExternalLinks: React.FC<ExternalLinkProps> = ({ spectra }) => {
    const { result, nonQualifiedResult, database, databaseUsed } = spectra;

    // hide for fungi
    if(database === "Fungi-SSP") return null;
    // hide for mosquito vectors
    if(database === "Mosquito-vectors") return null;

    // Combine result and nonQualifiedResult
    const resultArray = result ? [...result] : [];
    const nonQualifiedResultArray = nonQualifiedResult ? [...nonQualifiedResult] : [];
    const speciesList = resultArray.concat(nonQualifiedResultArray);

    // remove if entry has 3 or more words
    const filteredSpeciesList = speciesList.filter((species) => {
        // if "Empty spectrum" or "Unknown" is in the species name, do not show the external link
        if(species.includes("Empty spectrum")) return false;
        if(species.includes("Unknown")) return false;
        if(species.includes("Unknown germ")) return false;

        return species.split(" ").length < 3;
    });

    //if(filteredSpeciesList.length === 0) return null;

    return (
        <div className="external-links">
            <h3>External Links</h3>
            <ul>
                <GlossaryLink database={databaseUsed} />
                {filteredSpeciesList.length > 0 && filteredSpeciesList.map((species, index) => <NcbiLinkListElement species={species} key={index} />)}
                {filteredSpeciesList.length > 0 && filteredSpeciesList.map((species, index) => <BacdiveLinkListElement species={species} key={index}/>)}
            </ul>
        </div>
    )
}

export default ExternalLinks;