import React from 'react';
import {RedocStandalone} from "redoc";
import apiJson from "./openapi.json";

const APIRedoc: React.FC = () => {
    const json = {
        "openapi": "3.0.0",
        "info": {
            "title": "Mabriteccentral API",
            "version": "1.0.0",
            "description": "Your application must provide an API Key to be able to use the Mabriteccentral API.\n\nOptaining an API Key \n1. Open [Account Page](https://app.mabriteccentral.com/account). \n2. Create new API key by clicking <b>Generate API key</b> \n\nTo use an API Token to authorise a request, it must be added to the <b>x-api-key</b> header of every request: \n\n* x-api-key: API_TOKEN \n"
        },
        "servers": [
            {
                "url": "https://app.mabriteccentral.com/api/"
            }
        ],
        "paths": {
            "/verify-api-key": {
                "get": {
                    "security": [
                        {
                            "ApiKeyAuth": []
                        }
                    ],
                    "summary": "Checks if api key is valid",
                    "responses": {
                        "200": {
                            "description": "Valid API key",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "object",
                                        "properties": {
                                            "messgage": {
                                                "type": "string",
                                                "example": "API key is valid"
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "401": {
                            "$ref": "#/components/responses/UnauthorizedError"
                        }
                    }
                }
            },
            "/start-external-order": {
                "post": {
                    "security": [
                        {
                            "ApiKeyAuth": []
                        }
                    ],
                    "summary": "Start new order",
                    "requestBody": {
                        "description": "Start a new order",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object",
                                    "properties": {
                                        "customerReference": {
                                            "type": "string",
                                            "example": "ABCD"
                                        },
                                        "samples": {
                                            "type": "object",
                                            "properties": {
                                                "sample": {
                                                    "type": "string",
                                                    "example": "ABC_Sample"
                                                },
                                                "file": {
                                                    "type": "string",
                                                    "example": "ABC_Sample.txt"
                                                },
                                                "masses": {
                                                    "type": "array",
                                                    "items": {
                                                        "type": "object",
                                                        "properties": {
                                                            "mz": {
                                                                "type": "number",
                                                                "example": 3001.77
                                                            },
                                                            "int": {
                                                                "type": "number",
                                                                "example": 102593.59
                                                            }
                                                        },
                                                        "required": [
                                                            "mz",
                                                            "int"
                                                        ]
                                                    }
                                                }
                                            },
                                            "required": [
                                                "sample",
                                                "file",
                                                "masses"
                                            ]
                                        }
                                    },
                                    "required": [
                                        "samples"
                                    ]
                                }
                            }
                        }
                    },
                    "responses": {
                        "200": {
                            "description": "New order started",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "object",
                                        "properties": {
                                            "orderId": {
                                                "type": "string",
                                                "example": "23107a7e-fc4d-4228-865f-724266609474"
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "400": {
                            "description": "Bad Request",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "object",
                                        "properties": {
                                            "error": {
                                                "type": "string",
                                                "example": "Samples are missing."
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "401": {
                            "$ref": "#/components/responses/UnauthorizedError"
                        },
                        "403": {
                            "description": "Forbidden",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "object",
                                        "properties": {
                                            "error": {
                                                "type": "string",
                                                "example": "No active licence found."
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error"
                        }
                    }
                }
            }
        },
        "components": {
            "securitySchemes": {
                "ApiKeyAuth": {
                    "type": "apiKey",
                    "in": "header",
                    "name": "x-api-key"
                }
            },
            "responses": {
                "UnauthorizedError": {
                    "description": "API token is missing or invalid"
                }
            }
        }
    } // Replace with your OpenAPI spec URL

    return (
        <RedocStandalone
            spec={apiJson}
            options={{
                // theme: { colors: { primary: { main: "#dd5522" } } }, // Optional customization
                //scrollYOffset: "100", // Adjusts scroll offset for navigation
            }}
        />
    );
}

export default APIRedoc;