import React from 'react';
import {motion} from "framer-motion";
import {generatePath, Link} from "react-router-dom";
import {DOCS_GLOSSARY_ROUTE_SECTION} from "../../Routes";

type GlossaryLinkProps = {
    database?: string;
}

const GlossaryLink: React.FC<GlossaryLinkProps> = ({database, ...props}) => {
    if(!database) return null;
    if(database === "Fungi-SSP") return null;
    if(database === "Mosquito-vectors") return null;
    if(database === "All") return null;

    return (
        <motion.li
        initial={{
            opacity: 0,
        }}
        animate={{
            opacity: 1,
        }}
        {...props}
        >
            <Link to={generatePath(DOCS_GLOSSARY_ROUTE_SECTION, { section: database })} target="_blank">Glossary - {database} Classifier</Link>
        </motion.li>)
}

export default GlossaryLink;