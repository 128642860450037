import React, { useEffect, useState } from "react";
import { motion } from "motion/react";

const ncbiBaseURL = "https://api.ncbi.nlm.nih.gov/datasets/v2/taxonomy/taxon_suggest/";

const NcbiLinkListElement: React.FC<{ species: string, key: number }> = ({ species, ...props}) => {
    const [linkUrl, setLinkURL] = useState<string | undefined>();

    const getNCBITaxId = async (species: string) => {
        const response = await fetch(`${ncbiBaseURL}${encodeURIComponent(species)}?tax_rank_filter=higher_taxon&exact_match=true`);
        const { sci_name_and_ids } = await response.json();

        return sci_name_and_ids;
    };

    useEffect(() => {
        getNCBITaxId(species)
            .then((data) => {
                if (data.length > 0) {
                    const taxId = data[0].tax_id;
                    const url = `https://www.ncbi.nlm.nih.gov/datasets/taxonomy/${taxId}`;
                    setLinkURL(url);
                }
            })
            .catch((error) => {
                console.error('Error fetching NCBI taxon id', error);
            })
    }, [species]);

    if (!linkUrl) return null;

    return <motion.li
        initial={{
            opacity: 0,
        }}
        animate={{
            opacity: 1,
        }}
        transition={{ delay: props.key * 0.2}}
        {...props}
    ><a href={linkUrl} target="_blank" rel="noreferrer">NCBI - {species}</a></motion.li>;
};

export default NcbiLinkListElement;