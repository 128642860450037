import React from "react";
import { Spectra } from "../typings";
import { getSpectraWarning } from "../utils/get-spectra-warning";
import {createSearchParams, generatePath, Link} from "react-router-dom";
import {IDENTIFICATION_SPECTRA_DETAILS_ROUTE, MACO_MATCHES_ROUTE} from "../Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation as faTriangleExclamationSolid } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { motion } from "motion/react";
import {useHasRole} from "../hooks/useHasRole";

type Props = Spectra & {index: number};

export const SpectraRow: React.FC<Props> = ({
  file,
  sample,
  result,
  score,
  nonQualifiedScore,
  nonQualifiedResult,
  id,
  error,
  databaseUsed,
  database,
  masses,
    index
}) => {
  const { loading, hasRole } = useHasRole("compare");
  const hasWarning = getSpectraWarning(databaseUsed || database);

  const getMacoMatchesPath = (id: string) => {
    const path = generatePath(MACO_MATCHES_ROUTE, {
      id: id,
    })
    const params = {
        database: encodeURIComponent(database)
    }
    return `${path}?${createSearchParams(params)}`
  }

  // return placeholder while loading with height
  if (loading) return <div style={{ height: "100px" }}></div>;

  return (
    <motion.div
        key={index}
        initial={{
          opacity: 0,
          y: 25
        }}
        animate={{
          opacity: 1,
          y: 0
        }}
        transition={{ delay: index * 0.1}}
        className="col-12">
      <div className="detail-results__wrapper bg-white">
        <div className="row gy-20">
          <div className="col-12 col-sm-6 col-lg-2">
            <span className="text-break">
              <span className="d-block d-lg-none fw-bold">Filename:</span>
              {file}
            </span>
          </div>
          <div className="col-12 col-sm-6 col-lg-2">
            <span className="text-break">
              <span className="d-block d-lg-none fw-bold">Sample Name:</span>
              {sample}
            </span>
          </div>
          <div className="col-12 col-sm-6 col-lg-1">
            <div>
              <span className="d-block d-lg-none fw-bold">Datacount:</span>
              <div className="fw-bold">{masses && masses.length}</div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-1">
            <div>
              <span className="d-block d-lg-none fw-bold">
                Qualified score:
              </span>
              <div className="fw-bold">
                {score && score > 0 ? `${score}%` : "---"}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div>
              <span className="d-block d-lg-none fw-bold">
                Qualified result:
              </span>
              {result ? (
                result.map((profile, index) => (
                  <div className="result result-qualified" key={index}>
                    <span className="ball"></span>
                    {profile}
                  </div>
                ))
              ) : (
                <div className="result result-qualified">---</div>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-1">
            <div className="row gy-20">
              <div className="col-12">
                <div className="d-lg-none fw-bold">Non-Qualified score:</div>
                <div className="fw-bold">
                  {nonQualifiedScore && nonQualifiedScore > 0
                    ? `${nonQualifiedScore}%`
                    : "---"}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-2">
            <div className="row gy-20">
              <div className="col-12">
                <div className="d-lg-none fw-bold">Non-Qualified result:</div>
                {nonQualifiedResult ? (
                  nonQualifiedResult.map((profile, index) => (
                    <div
                      className={classNames({
                        result: true,
                        "result-not-qualified": true,
                        "result-none":
                          nonQualifiedScore && nonQualifiedScore < 70,
                      })}
                      key={index}
                    >
                      <span className="ball"></span>
                      {profile}
                    </div>
                  ))
                ) : (
                  <div className="result result-not-qualified">---</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 mt-30">
            <Link
              to={generatePath(IDENTIFICATION_SPECTRA_DETAILS_ROUTE, {
                id: id,
              })}
              className="btn btn-primary"
            >
              {(error || hasWarning) && (
                <FontAwesomeIcon
                  icon={faTriangleExclamationSolid as IconProp}
                  className="mr-5"
                />
              )}
              more details
            </Link>

            {hasRole && (
                <Link
                    to={getMacoMatchesPath(id)}
                    target="_blank"
                    className="btn btn-outline-primary ms-10"
                >
                  Compare
                </Link>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
