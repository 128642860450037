import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import {select} from "d3";
import useResizeObserver from "../../hooks/useRezizeObserver";

// Define the Cluster type
type ClusterNode = {
    children: ClusterNode[];
    height: number;
    size: number;
    index: number;
    isLeaf: boolean;
    species?: string; // Only define species for leaf nodes
};

export interface CircularDendrogramHierarchy extends d3.HierarchyCircularNode<ClusterNode> {
    radius: number;
}

type CircularDendrogramProps = {
    data: ClusterNode;
    selectedSpecies?: string[];
}

const MARGIN = { top: 50, right: 50, bottom: 50, left: 50 };

const CircularDendrogram: React.FC<CircularDendrogramProps> = ({ data, selectedSpecies }) => {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const dimensions = useResizeObserver(wrapperRef);

    const { width = 100, height = 100} = dimensions! || { width: 0, height: 0};

    const boundsWidth = Math.max(0, width - MARGIN.right - MARGIN.left);
    const boundsHeight = Math.max(0, height - MARGIN.top - MARGIN.bottom);

    const outerRadius = boundsWidth / 2;
    const innerRadius = outerRadius - 140;

    const maxLength = (d: d3.HierarchyNode<ClusterNode>): number => {
        return d.data.height + (d.children ? d3.max(d.children, maxLength) ?? 0 : 0);
    }

    const setRadius = (d: CircularDendrogramHierarchy, y0: number, k: number) => {
        d.radius = (y0 += d.data.height) * k;
        if (d.children) d.children.forEach(d => setRadius(d, y0, k));
    }

    const linkStep = (startAngle: number, startRadius: number, endAngle: number, endRadius: number) => {
        const c0 = Math.cos(startAngle = (startAngle - 90) / 180 * Math.PI);
        const s0 = Math.sin(startAngle);
        const c1 = Math.cos(endAngle = (endAngle - 90) / 180 * Math.PI);
        const s1 = Math.sin(endAngle);
        return "M" + startRadius * c0 + "," + startRadius * s0
            + (endAngle === startAngle ? "" : "A" + startRadius + "," + startRadius + " 0 0 " + (endAngle > startAngle ? 1 : 0) + " " + startRadius * c1 + "," + startRadius * s1)
            + "L" + endRadius * c1 + "," + endRadius * s1;
    }

    const linkExtensionConstant = (d: any) => {
        return linkStep(d.target.x, d.target.y, d.target.x, innerRadius);
    }

    const linkConstant = (d: any) => {
        return linkStep(d.source.x, d.source.y, d.target.x, d.target.y);
    }

    useEffect(() => {
        if (!data) return;
        if (!svgRef.current) return;
        const svg = select(svgRef.current);
        svg.selectAll('*').remove();

        const root = d3.hierarchy<ClusterNode>(data, (d) => d.children)
            .sum((d) => d.isLeaf ? 1 : 0)
            .sort((a: any, b: any) => (a.value - b.value) || d3.ascending(a.data.length, b.data.length))

        const cluster = d3.cluster<ClusterNode>()
            .size([360, innerRadius])
            .separation(() => 1);

        const zoom = d3.zoom<SVGSVGElement, unknown>()
            .scaleExtent([0.8, 5]) // Adjust min/max zoom levels
            .translateExtent([
                [-boundsWidth / 2, -boundsHeight / 2], // Minimum bounds
                [boundsWidth / 2, boundsHeight / 2],  // Maximum bounds
            ])
            .on("zoom", (event) => {
                g.attr("transform", event.transform.toString());
            });

        svg.call(zoom);

        cluster(root);

        setRadius(root as CircularDendrogramHierarchy, root.data.height = 0, innerRadius / maxLength(root));

        const bounds = Math.min(boundsWidth, boundsHeight); // Use the smaller dimension
        const g = svg
            .attr('viewBox', [-bounds / 2, -bounds / 2, bounds, bounds])
            .append('g')

        g.append("style").text(`
                    .link--active, .link--highlight {
                    stroke: #d3df00 !important;
              stroke-width: 2px;
            }
            
            .link-extension--active {
              stroke-opacity: .6;
              stroke: #d3df00 !important;
            }
            
            .label--active {
              font-weight: bold;
              fill: #d3df00 !important;
            }
            
            .highlight {
             font-weight: bold;
                fill: #d3df00 !important;
            }
        `)

        // linkExtension
        g.append("g")
            .attr("fill", "none")
            .attr("stroke", "#000")
            .attr("stroke-opacity", 0.25)
            .selectAll("path")
            .data(root.links().filter(d => !d.target.children))
            .join("path")
            .each(function (d: any) {
                d.target.linkExtensionNode = this;
            })
            .attr("d", linkExtensionConstant);


        // link
        g.append("g")
            .attr("fill", "none")
            .attr("stroke", "#000")
            .selectAll("path")
            .data(root.links())
            .join("path")
            .each(function (d: any) {
                d.target.linkNode = this;
            })
            .attr("d", linkConstant)
            .attr("stroke", "black");

        const leafCount = root.leaves().length;
        const fontSize = Math.max(4, Math.min(15, bounds / (leafCount * 0.4))); // Adjust font size dynamically

        const mouseOvered = function(active: boolean) {
            return (event: any, d: any) => {
                d3.select(event.target).classed("label--active", active).raise();
                d3.select(d.linkExtensionNode).classed("link-extension--active", active).raise();
                do d3.select(d.linkNode).classed("link--active", active).raise();
                while (d.parent && (d = d.parent));
            }
        }

        // leaf text
        g.append("g")
            .selectAll("text")
            .data(root.leaves())
            .join("text")
            .attr("dy", ".31em")
            .attr("transform", (d: any) => `rotate(${d.x - 90}) translate(${innerRadius + 2},0)${d.x < 180 ? "" : " rotate(180)"}`)
            .attr("text-anchor", (d: any) => d.x < 180 ? "start" : "end")
            .attr("font-size", fontSize)
            .text((d: any) => {
                return d.data.species || ""
            })
            .on("mouseover", mouseOvered(true))
            .on("mouseout", mouseOvered(false))
            .classed("highlight", (d: any) => !!(selectedSpecies && selectedSpecies.includes(d.data.species || "")))
            .each((d: any) => {
                // Highlight the path from the root to the leaf if the leaf is selected
                    if(!(selectedSpecies && selectedSpecies.includes(d.data.species || ""))) return;
                    do d3.select(d.linkNode).classed("link--highlight", true).raise();
                    while (d.parent && (d = d.parent));
                })

    }, [data, width, height]);

    return (
        <div ref={wrapperRef} style={{ width: "auto"}}>
            <svg ref={svgRef}></svg>
        </div>
    )
};

export default CircularDendrogram;
