import React, {useState} from 'react';
import {ClusterNode, Spectra} from "../../typings";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";
import CircularDendrogram from "./CircularDendrogram";

const getOverviewCluster = httpsCallable<{ profiles: string[], database: string}, ClusterNode>(functions, "getOverviewCluster");

type OverviewClusterProps = {
    spectra: Spectra
}

const OverviewCluster: React.FC<OverviewClusterProps> = ({ spectra }) => {
    const [isClusterDataLoading, setIsClusterDataLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [clusterData, setClusterData] = useState<ClusterNode>();

    const { matchedProfiles, databaseUsed, result, nonQualifiedResult, database } = spectra;

    const fetchClusterData = () => {
        if (!matchedProfiles) return;
        if (matchedProfiles.length === 0) return;
        if (!databaseUsed) return;

        setIsClusterDataLoading(true);

        getOverviewCluster({ profiles: matchedProfiles, database: databaseUsed })
            .then((result) => {
                setClusterData(result.data);
                setIsClusterDataLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setIsClusterDataLoading(false);
            });

    }

    if(!matchedProfiles) return null;
    // hide for fungi
    if(database === "Fungi-SSP") return null;

    // combine result and nonQualifiedResult
    const resultArray = result ? [...result] : [];
    const nonQualifiedResultArray = nonQualifiedResult ? [...nonQualifiedResult] : [];
    const combinedResult = resultArray.concat(nonQualifiedResultArray);

    return (
        <div>
            <h3>Cluster Analysis of Ribosomal Mass Profiles</h3>
    {
        !clusterData && <button
            type="submit"
                className="btn btn-primary"
                disabled={isClusterDataLoading}
                onClick={fetchClusterData}
            >
                {isClusterDataLoading ? (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    "Compute"
                )}
            </button>}
            <div className="w-100 mx-auto">
                {clusterData && <>
                  <p>All profiles from a matched genus are displayed.</p>
                  <CircularDendrogram data={clusterData} selectedSpecies={combinedResult} />
                </>}
                {error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
            </div>
        </div>
    )
}

export default OverviewCluster;

